.App {
  text-align: center;
}
body{
  font-family: 'Titillium Web',Arial,sans-serif;
  overflow: overlay;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* Font */
.header{
  font-weight: 700!important;
}
.container {
  pointer-events: none !important;
}

.container1 {
  pointer-events: auto !important;
}
.title{
  font-weight: 700!important;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('./Assets/font/TitilliumWeb-Regular.eot');
  src: url('./Assets/font/TitilliumWeb-Regular.eot?#iefix') format('embedded-opentype'),
      url('./Assets/font/TitilliumWeb-Regular.woff2') format('woff2'),
      url('./Assets/font/TitilliumWeb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('./Assets/font/TitilliumWeb-ExtraLightItalic.eot');
  src: url('./Assets/font/TitilliumWeb-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('./Assets/font/TitilliumWeb-ExtraLightItalic.woff2') format('woff2'),
      url('./Assets/font/TitilliumWeb-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('./Assets/font/TitilliumWeb-ExtraLight.eot');
  src: url('./Assets/font/TitilliumWeb-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('./Assets/font/TitilliumWeb-ExtraLight.woff2') format('woff2'),
      url('./Assets/font/TitilliumWeb-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('./Assets/font/TitilliumWeb-Italic.eot');
  src: url('./Assets/font/TitilliumWeb-Italic.eot?#iefix') format('embedded-opentype'),
      url('./Assets/font/TitilliumWeb-Italic.woff2') format('woff2'),
      url('./Assets/font/TitilliumWeb-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('./Assets/font/TitilliumWeb-Black.eot');
  src: url('./Assets/font/TitilliumWeb-Black.eot?#iefix') format('embedded-opentype'),
      url('./Assets/font/TitilliumWeb-Black.woff2') format('woff2'),
      url('./Assets/font/TitilliumWeb-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('./Assets/font/TitilliumWeb-BoldItalic.eot');
  src: url('./Assets/font/TitilliumWeb-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./Assets/font/TitilliumWeb-BoldItalic.woff2') format('woff2'),
      url('./Assets/font/TitilliumWeb-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('./Assets/font/TitilliumWeb-Light.eot');
  src: url('./Assets/font/TitilliumWeb-Light.eot?#iefix') format('embedded-opentype'),
      url('./Assets/font/TitilliumWeb-Light.woff2') format('woff2'),
      url('./Assets/font/TitilliumWeb-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('./Assets/font/TitilliumWeb-Bold.eot');
  src: url('./Assets/font/TitilliumWeb-Bold.eot?#iefix') format('embedded-opentype'),
      url('./Assets/font/TitilliumWeb-Bold.woff2') format('woff2'),
      url('./Assets/font/TitilliumWeb-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('./Assets/font/TitilliumWeb-LightItalic.eot');
  src: url('./Assets/font/TitilliumWeb-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('./Assets/font/TitilliumWeb-LightItalic.woff2') format('woff2'),
      url('./Assets/font/TitilliumWeb-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('./Assets/font/TitilliumWeb-SemiBold.eot');
  src: url('./Assets/font/TitilliumWeb-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('./Assets/font/TitilliumWeb-SemiBold.woff2') format('woff2'),
      url('./Assets/font/TitilliumWeb-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Titillium Web';
  src: url('./Assets/font/TitilliumWeb-SemiBoldItalic.eot');
  src: url('./Assets/font/TitilliumWeb-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('./Assets/font/TitilliumWeb-SemiBoldItalic.woff2') format('woff2'),
      url('./Assets/font/TitilliumWeb-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.Back_photo {
  min-height: 1000px!important;
}
@media (max-width: 768px) {
  .contact-bg{
    min-height: 500px !important;
  }
}

@media (min-width: 651px) and (max-width: 1000px) {
  .home-pg-video {
    width: 50% !important;
    margin-top: 2px !important;
    top: 20% !important;
  }
  .home-pg-bg {
    width: 50% !important;
    margin-top: 2px !important;
    top: 20% !important;
  }
  .home-pg-bg span,button{
    margin-top: 10px !important;
  }
  .home-pg-video h2{
    font-size: xx-large !important;
    /* margin-bottom: 0px !important; */
  }
  .contact-bg{
    min-height: 650px !important;
  }
}
@media(max-height:700px){
  .Back_photo2 {
    min-height: 700px !important;
  }
}
@media (max-width: 450px) {
  .Back_photo {
    min-height: 400px !important;
  }
  .errorFont{
    font-size: 3rem !important;
  }
  .btn-error{
    height:30px !important;
  }
  .logo-section{
    min-height: unset !important;
  }
  .home-pg-video {
    width: 170px !important;
    margin-top: 1px !important;
    top: 25% !important;
  }
  .parallax-component1 .carousel-indicators{
    height:15px !important;
    width: 40px !important;
  }
  .parallax-component1 .carousel .carousel-indicators button{
    width: 10px !important;
    height: 10px !important;
    border-radius: 100% !important;
  }
  .vdo_two{
    top: 20% !important;
    width: 200px !important;
  }
  .home-pg-video h2{
    margin-bottom: 0px !important;
  }
  .home-pg-video h3{
    margin-bottom: 1px !important;
  }
  .nx-world-bg{
    top:13% !important;
    line-height: 17px !important;
  }
  .nx-world-bg p,h4{
   margin-bottom: 0.3rem !important;
  }
     .nx-ops2{
      top:10% !important;
      width: 60% !important;
    }
    .contact-logo{
      width:200px !important;
    }
    .contact-bg{
      min-height: 450px !important;
    }
    .input-field{
      top:5% !important;
      margin: 4px 0px 4px 0px !important;
    }
   .parallax-component{
    height:500px !important;
   }
   .evercomm_logo{
    width:80px !important;
   }
   .nav_logo {
   height: 23px !important;
   }
   .nav_logo_unreachable {
    height: 27px !important;
    width: 54px !important;
   }
   
   .navbar-toggler-icon{
    width: 20px !important;
   }
}
@media (max-width: 350px) {
  .fn_script{
    padding-right: 16px!important;
  }
  .home-pg-video {
    width: 170px !important;
    margin-top: 1px !important;
    top: 10% !important;
  }
  .btn-error{
    height:25px !important;
    width:100px !important;
    margin-top: 0 !important;
  }
  .errorFont{
font-size: 2rem !important;
  }
  .home-pg-bg {
    margin-top: 2px !important;
    top: 5% !important;
  }
  .nx-ops-bg{
    top:20% !important;
  }
  .nx-ops2-bg{
    top:20% !important;
  }
  .nx-ops2{
    top:10% !important;
    width: 70% !important;
  }
  .nx-world-bg{
    top:5% !important;
  }

}
@media (min-width: 450px) and (max-width: 651px) {
  .home-pg-bg {
    margin-top: 2px !important;
    top: 10% !important;
  }
  .home-pg-bg span,button{
    margin-top: 4px !important;
  }
  .home-pg-video {
    width: 200px !important;
    margin-top: 2px !important;
    top: 20% !important;
  }
  .btn-error{
    height:35px !important;
  }
  .home-pg-video h2{
    margin-bottom: 0px !important;
  }
  .parallax-component1 .carousel-indicators{
    height:25px !important;
    width:65px !important;
  }
  .nx-ops2-bg{
    top:10% !important;
  }
  .nx-world-bg{
    top:5% !important;
    line-height: 20px !important;
  }
  .nx-ops2{
    left: 5% !important;
    top:10% !important;
    width: 60% !important;
  }
  .contact-bg{
    min-height: 500px !important;
  }
  .input-field{
    top:10% !important;
    margin: 4px 0px 4px 0px !important;
  }
  .parallax-component1 .carousel .carousel-indicators button{
    width: 10px !important;
    height: 10px !important;
    border-radius: 100% !important;
    margin-top:0px !important;
  }

   .nav_logo{
    height: 30px !important;
   }
   .nav_logo_unreachable{
    height: 30px !important;
    width: 60px !important;
   }
   .navbar-toggler-icon{
    width: 25px !important;
   }
   .succ_stories_img{
    height:60px !important;
    margin: 25px 30px 25px 0px !important;
  }
}
@media (min-width: 650px) and (max-width: 1100px) {
  .parallax-component1 .carousel-indicators{
    height:25px !important;
    width: 65px !important;
  }
  .btn-error{
    height:40px !important;
  }
  .parallax-component1 .carousel .carousel-indicators button{
    width: 10px !important;
    height: 10px !important;
    border-radius: 100% !important;
    margin-top:0px !important;
  }
  .succ_stories_img{
    height:80px !important;
    margin: 25px 30px 25px 0px !important;
  }
  .navbar-expand-lg .navbar-toggler{
    margin-top: 0px !important;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .nx-ops2{
    left: 10% !important;
    top:15% !important;
    width: 60% !important;
  }
  .nxMapIcon{
    width: 150px !important
  }
  .singapore_training{
    width: 100px !important
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blu_title{
  color:#0048b1;
  font-weight:bold;
}

/* navbar styles */


@media (max-width: 396px) {
  .bureau_evercomm{
    margin: 0px 10px !important;
  }
  .navbar-collapse.show {
     top: 107.4px;
  }
}
@media (min-width: 320px) and (max-width: 418px) {
  
  .evercomm_logo{
    width: 70px !important;
   }
  .navbar-collapse.show {
     top: 86.45px;
  }
}

@media (min-width: 418px) and (max-width: 500px) {
  
  .evercomm_logo{
    width: 100px !important;
   }
  .navbar-collapse.show {
     top: 86.45px;
  }
}
@media (min-width: 500px) and (max-width: 768px) {
  .navbar-brand{
    margin-right: 18px !important;
  }
}
@media (min-width: 500px) and (max-width: 651px) {
  .evercomm_logo{
    width:100px !important;
   }
  .navbar-collapse.show {
     top: 86.45px;
  }
}

@media (min-width: 651px) and (max-width: 1002px) {
  .navbar-collapse.show {
     top: 86.45px;
  }
  .nav_logo{
    height: 40px !important;
   }
   .nav_logo_unreachable{
    height: 40px !important;
    width: 80px !important;
   }
}
@media (min-width: 1002px) and (max-width: 1099px) {
  .navbar-collapse.show {
    top: 86.45px;
  }
}
@media (min-width: 1100px) and (max-width: 1152px) {
  .navbar-collapse.show {
     top: 86.45px;
  }
}
@media (max-width: 1152px) {
  .navbar-collapse {
    position: fixed;
    top: 66px;
    bottom: 0;
    right: -80%; /* Adjust this value to change the width of the collapsed navbar */
    width: 230px; /* Adjust this value to change the maximum width of the collapsed navbar */
    padding: 20px 2px;
    z-index: 999;
    transition: all 0.3s ease-in-out;
  }
  .navbar-collapse.show {
    right: 0;
    background-color: rgba(225,225,225,0.8) !important;
  }
  .navbar-collapse.show .navbar-nav{
    background: transparent !important;
  }
  .navbar-collapse .navbar-nav{
    background-color: transparent !important;
  }
  .component-container.open-toggle{
    opacity: 0.7;
  }
  .body.open-toggle{
    overflow: hidden;
  }
  .navbar-expand-lg .navbar-toggler {
    display: block !important;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: column !important;
    display: flex !important;
    align-items: start !important;
  }
}
.navbar-collapse .navbar-nav{
  background: white;
  border-radius: 30px;
}
.nav-link span:hover, .enterprise-solution .dropdown-item:hover{
  background: rgb(0, 44, 150);
  color:white !important;
}
.nav-link span a:hover{
  color:white !important;
}
.navbar-toggler:focus{
  box-shadow: 0 0 0 0.07rem #007bff;
}
.enterprise-solution.dropdown-menu {
  animation: fade-in 0.5s;
}
@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}
.enterprise-solution .dropdown-item{
  background: #fff;
}
#navbarCollapse{
  display: flex !important;
  align-items: start !important;
}
.enterprise-solution .dropdown:hover .dropdown-menu {
  display:block;
  margin-top: 0px;
}
.navbar-toggler[aria-expanded="true"] ~ .App{
  background: #61dafb !important;
  overflow: hidden !important;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background-image: url("Assets/Images/x.svg");
}
.navbar-toggler[aria-expanded="false"] .navbar-toggler-icon {
  background-image: url("Assets/Images/list.svg");
}

/* ::-webkit-scrollbar {
  width: 0;  Remove scrollbar space
  background: transparent;  Optional: just make scrollbar invisible
} */


/* Set the video container to full screen */
.video-container {
  /* position: relative;
  height: 100vh;
  width: 100%; */
  overflow: hidden;
  height: 60vh;
}

/* Set the video to cover the entire container */
.video-container video {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: 60vh;
  object-fit: cover;
  z-index: -1;
}

/* Set the content container to the center of the screen */
.content-container {
  position: relative;
  top: 49%;
  /* left: 50%; */
  transform: translate(0%, -150%);
  text-align: center;
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
}

/* Set the content to appear in front of the video */
.content {
  position: relative;
  z-index: 1;
}

/* Set the first content to appear on the screen */
#content1 {
  opacity: 0;
  transition: opacity 0.5s;
  
}
/* Set the second content to appear when scrolled into view */
#content1.show {
  opacity: 1;
}

/* Set the second content to be hidden initially */
#content2 {
  opacity: 0;
  transition: opacity 0.5s;
}

/* Set the second content to appear when scrolled into view */
#content2.show {
  opacity: 1;
}
.parallax-component1 .carousel .carousel-indicators{
 margin: 0px !important;
 left:50% !important;
 width: 125px !important;
 border-radius: 20px !important;
 height:24px !important;
 transform: translate(-50%,-50%) !important;
 background-color: rgba(225, 225, 225, 0.3);
 display: flex !important;
align-items: center !important;


}
.parallax-component1 .carousel .carousel-indicators button{
  width: 10px;
  height: 10px;
  border-radius: 100%;
  filter: drop-shadow(1px 1px 1px #000);
  margin-left: 4px !important;
margin-right: 4px !important;
  
}
.parallax-component1 .carousel .carousel-indicators button.active{
  background-color: #007bff;
}
.nx-map-short:hover{
  background-image: #007bff !important ;
}
.nx-map-short:hover .nx-map-abbr{
 display: block !important;
 color: #007bff !important;
}
.nx-ops-abbr:hover {
  background-color: #007bff !important;
}

.flip-card-inner {

  width: 100%;
 min-height: 130px;
 height: 100%;
  text-align: center;
  /* transition: transform 0.6s; */
  transform-style: preserve-3d;
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #003f9c;
  color: white;
  transform: rotateY(180deg);
  text-align: start;
}

.enchanced_features {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  padding: 50px 50px !important;
 }
 .enchanced_features div {
  background-color: #fff;
  border-radius: 5px;
  background: linear-gradient(180deg, #1c1eabdb, rgba(45, 91, 198, 0.6));
  color: #fff;
  height: 200px;
  padding : 20px 20px;
 }

/* for title */
@media (max-width:450px) {

 .header{
  font-size: 1.1rem !important;
 }
 .title{
  font-size: 1rem !important;
 }
 .subtitle{
  font-size: 0.9rem !important;
 }
 .nx_text{
  font-size: 1.3rem !important;
}
 .texts{
  font-size: 14px !important;
 }
 .location_texts{
  font-size: 14px !important;
 }
 .homepage-bg{
  top:5% !important;
 }
 .contact_input{
   min-height: 30px !important;
   height: 35px !important;
 }
 .contact_textarea {
  min-height: 70px !important;
 }
 .form-text {
font-size: 14px !important;
 }
 .button{
  margin-top:5px !important;
  height: 25px !important;
  width:162px !important;
 } 
 .arti4 button{
  margin-top:5px !important;
  height: 30px !important;
  width: 170px !important;
  padding-top: 4px !important;
 }
 .article2_btn{
  margin-top:5px !important;
  height: 30px !important;
  width:200px !important;
 }
 .Evercomm_Anni_btn{
  height: 25px !important;
 }
 .button-one{
  margin-top:5px !important;
  height: 25px !important;
 }
 .logo{
  width:100px !important;
 }
 .opsLogo{
  width:100px !important;
 }
 .Plan_logo{
  width:100px !important;
 }
 .carousel-inner h5{
  margin-top: 3px !important;
  margin-bottom: 3px !important;
 }

 .trainning-bg{
  margin-top: 100px !important;
  margin-bottom: 100px !important;
 }
 .quote-bg{
  margin-top: 50px !important;
  margin-bottom: 50px !important;
 }
 .parallax-component1{
  height:450px !important;
  /* min-height:350px !important; */
 }
 .ctbc_banner{
  background-position: 75% 50% !important;

 }
 .wealth_script{
  top:10px !important;
 }
 .nxMapIcon{
  width: 150px !important
}
.singapore_training{
  width: 100px !important
}
}
@media (min-width:450px) and (max-width:600px) {
  .carousel-inner h5{
    margin-top: 9px !important;
    margin-bottom: 9px !important;
   }
  
   .trainning-bg{
    margin-top: 100px !important;
    margin-bottom: 100px !important;
   }
   .quote-bg{
    margin-top: 50px !important;
    margin-bottom: 50px !important;
   }
     .sg_innovate_logo{
      height: 40px !important;
     }
     .bCorp_logo{
      height: 40px !important;
     }
     .nxMapIcon {
      width: 90px !important;
     }
     .singapore_training{
      width: 40px !important
    }
}
@media (min-width:600px) and (max-width:800px) {
  .carousel-inner h5{
    margin-top: 25px !important;
    margin-bottom: 25px !important;
   }
 
   .trainning-bg{
    margin-top: 120px !important;
    margin-bottom: 120px !important;
   }
   .quote-bg{
    margin-top: 50px !important;
    margin-bottom: 50px !important;
   }
     .sg_innovate_logo{
      height: 50px !important;
     }
     .bCorp_logo{
      height: 50px !important;
     }
     .nxMapIcon {
      width: 120px !important;
     }
     .singapore_training{
      width: 70px !important
    }
}
@media (min-width:450px) and (max-width:800px) {
  .header{
    font-size: 1.7rem !important;
 }
 .title{
  font-size: 1.5rem !important;
 }
 .nx_text{
  font-size: 2rem !important;
}
 .subtitle{
  font-size: 1.1rem !important;
 }
 .texts{
  font-size: 15px !important;
 }
 .location_texts{
  font-size: 15px !important;
 }
 .homepage-bg{
  top:10% !important;
 }
 .button{
  height: 35px !important;
   width:236px !important;
 }
 .btn_cop{
  height: 35px !important;
 }
 .article2_btn{
  height: 35px !important;
   width:236px !important;
 }
 .Evercomm_Anni_btn{
  height: 35px !important;
 }
 .button-one{
  margin-top:5px !important;
  height: 35px !important;
   
 }
 .logo{
  width:150px !important;
 }
 .opsLogo{
  width:150px !important;
 }
 .Plan_logo{
  width:150px !important;
 }
 .parallax-component{
  height:600px !important;
  /* min-height:70vh !important; */
 }
 .parallax-component1{
  height:600px !important;
 }
 .ctbc_banner{
  background-position: 75% 50% !important;
 }
 .evercomm-tag img{
  height: 30px !important;
 }
 .evercomm-tag h5{
  margin-top: 7px !important;
  margin-bottom: 0px !important;
 }
 .wrapper h4{
  font-size: 0.9rem !important;
 }
}
@media (min-width:800px) and (max-width:1100px) {
  .header{
    font-size:2.7rem !important;
  }
  .title{
    font-size: 1.9rem !important;
  }
  .subtitle{
    font-size: 1.5rem !important;
  }
  .texts{
    font-size: 18px !important;
  }
  .button{
    margin-top:9px !important;
    height: 35px !important;
    width:375px !important;
   }
   .btn_cop{
    height: 35px !important;
   }
   .Evercomm_Anni_btn{
    height: 35px !important;
   }
   .button-one{
    margin-top:5px !important;
    height: 35px !important;
   }
   
   .logo{
    width:250px !important;
   }
   .opsLogo{
    width:200px !important;
   }
   .Plan_logo{
    width:200px !important;
   }
   .trainning-bg{
    margin-top: 150px !important;
    margin-bottom: 150px !important;
   }
   .quote-bg{
    margin-top: 50px !important;
    margin-bottom: 50px !important;
   }
   .parallax-component{
    height:700px !important;
   }
   .parallax-component1{
    height:650px !important;
   }
 
   .wrapper h4{
    font-size: 1.1rem !important;
   }
   .wrapper h5{
    font-size: 1rem !important;
   }
  
}
@media (min-width:1100px) and (max-width:1290px) {
  .title{
    font-size: 2.2rem !important;
  }
}

.img-blk {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 80% 60%;
  background-size: cover;
  padding: 40px 0px;
}
@media only screen and (max-device-width: 1366px) {
  .img-blk {
    background-attachment: scroll;
  }
} 
@media only screen and (min-width: 500px){

  .img-blk{
    padding: 100px 0px;
  }
}
.mask1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:rgba(8, 28, 94, 0.7)
}
.mask2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:rgba(17, 5, 129, 0.856)
}
.mask3::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:rgba(0, 0, 0, 0.25)
}
.mask4::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(31, 33, 165, 0.856), rgba(255,255,255,0),rgba(255,255,255,0));
}
.mask5::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(2, 3, 95, 0.856), rgba(2, 4, 116, 0.856), rgba(31, 33, 165, 0.6),rgba(255,255,255,0));
}
.mask6::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 1, 32, 0.856),rgba(255,255,255,0),rgba(255,255,255,0));
}
@media only screen and (max-width: 536px){
  .gov_n_inst img {
    height: 35px !important;
    object-fit: contain !important;
  }
}

@media (min-width:768px) and (max-width:1122px){
  .gov_n_inst img {
    height: 35px !important
  }
}

@media only screen and (max-width: 536px){
  .financial_img {
    height: 35px !important;
  }
  .financial_img_1 {
    height: 45px !important;
  }
  .financial_img_2 {
    height: 65px !important;
  }
}

@media (min-width:768px) and (max-width:1122px){
  .financial_img {
    height: 35px !important
  }
  .financial_img_1 {
    height: 45px !important;
  }
  .financial_img_2 {
    height: 65px !important;
  }
}

 /* for flip cards */
 @media (min-width:800px) and (max-width:1000px){
  .flip-one{
width:230px !important;
  }
  .flip-nxplan{
    width: 230px !important;
      }
  .flip-two{
width:220px !important;
  }
  .flip-three{
    width:320px !important;
  }
  .flip-card{
    min-height: 200px !important;
  }
 }
 @media (min-width:450px) and (max-width:800px){
  .flip-one{
width:200px !important;
  }
  .flip-nxplan{
    width: 210px !important;
      }
  .flip-two{
width:190px !important;
  }
  .flip-three{
    width:290px !important;
  }
  .flip-card{
    min-height: 200px !important;
  }
 }
 @media (max-width:450px){
  .flip-one{
width:140px !important;
  }
  .flip-nxplan{
width: 147px !important;
  }
  .flip-two{
width:130px !important;
  }
  .flip-three{
    width:190px !important;
  }
  .flip-card{
    min-height: 170px !important;
  }
  .succ_stories_img{
    height:60px !important;
    margin: 25px 25px 25px 0px !important;
  }
  .evercomm-tag{
    right: 10px !important;
  }
  .evercomm-tag img{
    width: 60px !important;
    height: 20px !important;
   }
   .evercomm-tag h5{
    margin-top:2px !important;
    margin-bottom: 0px !important;
   }
 }
 
 .content_.show{
  opacity: 1;
}

.content_{
  opacity: 0;
  transition: opacity 0.5s;
}
 
.vertical-timeline::before {
  top: 34px !important;
}

@media (max-width: 767px) {
  .milestones_component{
    position: relative !important;
    height: 400px !important;
    /* margin-right: 10px !important; */
  }
}

::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: rgb(36, 36, 36);  */
  background: transparent;
  border-radius: 10px;

}

/* Handle */
::-webkit-scrollbar-thumb:vertical {
  background: rgb(189, 188, 188, 0.7); /*older color : #888, old color: rgb(189, 188, 188)*/
  border-radius: 1px;
  background-clip: content-box;
  border: 1px solid transparent;
}

/* scrollbar corner */
::-webkit-scrollbar-corner {
  display: none;
}

.lang-selector .btn-check:focus+.btn, .btn:focus {
  box-shadow: none;
}
 
@media (max-width:1152px){
  .enterprise-solution .dropdown-menu {
    display:block;
    margin-top: 8px;
  }
}

.footer-item:hover {
  text-decoration: underline;
}
/* VideoBackground */
@media (min-width:870px) and (max-width:1030px){
.home-video-bg{
min-height: 50vh !important;
}
}
@media (min-width:650px) and (max-width:870px){
  .home-video-bg{
  min-height: 30vh !important;
  }
  }
  @media (min-width:500px) and (max-width:650px){
    .home-video-bg{
    min-height: 400px !important;
    width: fit-content !important;
    }
    }
  @media (max-width:500px){
    .wrapper {
      grid-auto-rows: auto !important;
    }
    .wrapper p{
      font-size: 10px !important;
    }
    .home-video-bg{
    min-height: 350px !important;
   object-fit: none !important;
    }
    }
    /* logo */
    @media (min-width:768px) and (max-width:900px) {
      .enchanced_features {
        gap: 35px;
        padding: 35px 35px !important;
       }
      .financial_logo1{
        height: 35px !important;
        margin-bottom: 15px !important;
        margin-top: 15px !important;
        margin-right: 20px !important;
      }
      .financial_logo2{
        height: 35px !important;
        margin-bottom: 15px !important;
        margin-top: 15px !important;
        margin-right: 20px !important;
      }
      .financial_logo3{
        height: 35px !important;
        margin-bottom: 15px !important;
        margin-top: 15px !important;
        margin-right: 20px !important;
      }
      .financial_logo4{
        height: 35px !important;
      }
    }
/* button */
@keyframes hover {
	50% {
		transform: translateY(-3px);
	}
	100% {
		transform: translateY(-6px);
	}
}

@media(max-width:500px){
  .art2_header{
    font-size: 1.8rem !important;
    }

  .EverComm_Anni_logo{
    width: 150px !important;
  }
  .ADS_el{
    background-color: #fff !important;
  }
  .res-bg{
    min-height: 50vh !important;
  }
  .location_res-bg{
    min-height: 110vh !important;

  }
  .contact-form{
    min-height: 30vh !important;
  }
  .res-form{
    min-height: 40vh !important;
    width: 70% !important;
  }
  .res-form input{
    min-height: 35px !important;
  }
  .res-formbtn {
    min-height: 35px !important;
  }
  .res-form textarea{
    min-height: 70px !important;
  }
}
@media (max-width:350px) {
  .ADS_logo{
    min-width: 35px !important;
    width:35px !important;
  }
  .subheader{
    font-size: 5rem !important;
  }
 .nx_text{
    font-size: 1.5rem !important;
  }
  .evercomm10_banner{
    width: 200px !important;
   }
   .Evercomm_Anni_whiteLogo{
    width: 200px !important;
   }

}
@media (min-width:350px) and (max-width:500px) {
  .subheader{
    font-size: 7rem !important;
  }
  .ADS_logo{
    min-width: 40px !important;
    width: 40px !important;
  }
  .evercomm10_banner{
    width: 250px !important;
   }
   .Evercomm_Anni_whiteLogo{
    width: 300px !important;
   }
   .b_corp_logo {
    width: 100px !important;
    height: 140px !important;
  }

}
@media(min-width:500px) and (max-width:900px){
  .subheader{
    font-size: 7rem !important;
  }
  .res-bg{
    min-height: 75vh !important;
  }
  .location_res-bg{
    min-height: 80vh !important;

  }
  .contact-form{
    min-height: 35vh !important;
  }
  .res-form{
    min-height: 40vh !important;
    width: 70% !important;
  }
  .EverComm_Anni_logo{
    width: 200px !important;
  }
  .enchanced_features div{
    height: 230px !important
  }
}
  @media(min-width:900px) and (max-width:1200px){
    .res-form{
      min-height: 50vh !important;
      width: 70% !important;
    }
    .location_res-bg{
      min-height: 90vh !important;
  
    }
    .enchanced_features div{
      height: 250px !important
    }
  }
 
  @media(max-width:768px){
    .res-aboutBg{
      height:250px !important;
      display: flex !important;
      align-items: center !important;
    }
    .posts_tag{
      font-size: 0.7rem !important;
    }
  }
  @media(min-width:1200px) and (max-width:1400px){
    .wealth_banner{
     min-width: 555px !important;
    }
    .enchanced_features div {
    height: 210px !important;
    }
  }
  @media(min-width:700px) and (max-width:900px) {
    .evercomm_mitsubishi img {
      width: 100px !important;
      height: 40px !important;
      margin-left: 10px !important;
    }
  }
  @media(min-width:900px) and (max-width:1100px) {
    .evercomm_mitsubishi img {
      width: 110px !important;
      height: 43px !important;
      margin-left: 20px !important;
    }
  }
  @media(min-width:1100px) and (max-width:1200px) {
    .evercomm_mitsubishi img {
      width: 110px !important;
      height: 43px !important;
      margin-left: 20px !important;
    }
    .navbar-brand{
      margin-right: 25px !important;
    }
    .FA_BCorp_logo, .FA_COP28_logo, .FA_SGC_logo, .unresonable_logo_nav{
      margin-left: 25px !important;
    }
    .nav_logo{
      height: 40px !important;
     }
     .nav_logo_unreachable{
      height: 40px !important;
      width: 80px !important;
     }
     .textOne {
      top: 23% !important;
      left: 8% !important;
    }
    .textTwo {
      top: 53% !important;
      left: 70% !important;
    } 
  }
  @media(min-width:1200px) and (max-width:1350px) {
    .evercomm_mitsubishi img {
      width: 140px !important;
      height: 50px !important;
      margin-left: 30px !important;
    }
    .evercomm_logo {
      width:160px !important;
    }
  
    .nav_logo{
     height: 40px !important;
    }
    .nav_logo_unreachable{
      height: 40px !important;
      width: 80px !important;
     }
  }
  @media(min-width:1350px) and (max-width:1500px) {
    .navbar-brand{
      margin-right: 25px !important;
    }
    .FA_BCorp_logo, .FA_COP28_logo, .FA_SGC_logo, .unresonable_logo_nav{
      margin-left: 25px !important;
    }
    .evercomm_logo {
      width:160px !important;
    }
  }
  @media(min-width:550px) and (max-width:700px) {
      .evercomm_mitsubishi {
        margin-top: 35px !important;
        margin-bottom: 35px !important;
        gap: 1.8rem !important;
      }
  
      .evercomm_mitsubishi img {
        width: 85px !important;
        height: 35px !important;
        margin-left: 10px !important;

      }
  }
  @media(min-width:480px) and (max-width:550px) {
    .evercomm_mitsubishi {
      margin-top: 30px !important;
      margin-bottom: 30px !important;
      gap: 1.5rem !important;
    }

    .evercomm_mitsubishi img {
      width: 75px !important;
      height: 28px !important;
    margin-left: 10px !important;

    }
}
@media (max-width:480px) {
  .evercomm_mitsubishi {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    gap: 1rem !important;
  }

  .evercomm_mitsubishi img {
    width: 60px !important;
    height: 25px !important;
    margin-left: 10px !important;
  }
 
}
.quote-bg p{
  font-weight: normal !important;
}
 .carousel-item{
  transition: transform 0.7s ease-in-out !important;
 }
 .wrapper {
  display: grid;
  grid-template-columns:  repeat(4,minmax(100px, 1fr));
  gap: 35px;
  grid-auto-rows: minmax(200px, 400px);

}
.wrapper :nth-child(1) {
  grid-column: 1/3;
  grid-row: 1 / 3;
}
.wrapper :nth-child(2) {
  grid-column: 3/4;
  grid-row: 1;
}
.wrapper :nth-child(3) {
  grid-column: 4/5;
  grid-row: 1;
}

@media (min-width:1400px) and (max-width:1600px) {
  .art2_header{
  font-size: 3.4rem !important;
  }
  .thumbnail {
    height: 60% !important;
  }
  .wrapper {
    grid-auto-rows:  minmax(200px, 330px);
  }
  .evercomm_10font{
   font-size: 1.9rem !important;
  }
}
@media (min-width:1200px) and (max-width:1400px) {
  .art2_header{
    font-size: 3.2rem !important;
    }
  .double_quote{
    width: 37px !important;
  }
  .evercomm_10font{
    font-size: 1.6rem !important;
  }
  .thumbnail {
    height: 58% !important;
  }
  .wrapper {
    grid-auto-rows:  minmax(200px, 300px);
  
  }
  .wrapper h2{
   font-size: 1.4rem !important;
  }
  .wrapper h3{
    font-size: 2.5rem !important;
   }
   .evercomm10_banner{
    width: 600px !important;
   }
   .Evercomm_Anni_whiteLogo{
    width: 700px !important;
   }
   .navbar-brand{
    margin-right: 18px !important;
  }
  .FA_BCorp_logo, .FA_COP28_logo, .FA_SGC_logo, .unresonable_logo_nav{
    margin-left: 18px !important;
  }
}
@media (min-width:1000px) and (max-width:1200px) {
  .art2_header{
    font-size: 3rem !important;
    }
  .double_quote{
    width: 30px !important;
  }
  .evercomm_10font{
    font-size: 1.5rem !important;
  }
  .thumbnail {
    height: 65% !important;
  }
  .wrapper {
    grid-auto-rows:  minmax(200px, 230px);
    gap: 30px;
  
  }
  .wrapper h2{
    font-size: 1.1rem !important;
   }
   .wrapper h3{
    font-size: 2rem !important;
   }
   .wrapper p{
    font-size: 12px !important;
  }
  .evercomm10_banner{
    width: 500px !important;
   }
   .Evercomm_Anni_whiteLogo{
    width: 600px !important;
   }
   .mapLogo{
    width: 150px !important;
   }
   .ctbc_banner{
    background-position: 40% 50% !important;
   }
   .ctbc_script_container{
    margin-left: 30px !important;
  }
   .ctbc_banner_script{
    padding: 50px 50px !important;
    width: 500px !important;
   }
}
@media (min-width:800px) and (max-width:1000px) {
  .art2_header{
    font-size: 2.4rem !important;
    }
  .double_quote{
    width: 25px !important;
  }
  .evercomm_10font{
    font-size: 1.4rem !important;
  }
  .thumbnail {
    height: 60% !important;
  }
  .wrapper p{
    font-size: 10px !important;
  }
  .news_tags {
    padding-left: 20px !important;
    padding-top: 20px !important;
  }
  .wrapper {
    grid-auto-rows:  minmax(200px, 200px);
    gap: 30px;
  }
  .wrapper h2{
    font-size: 0.9rem !important;
   }
   .evercomm10_banner{
    width: 400px !important;
   }
   .Evercomm_Anni_whiteLogo{
    width: 500px !important;
   }
   .mapLogo{
    width: 130px !important;
   }
}

@media (min-width:700px) and (max-width:799px) {
  .pkk_modal{
    margin-top: 50px !important;
  }
  .art2_header{
    font-size: 2.2rem !important;
    }
  .mapLogo{
    width: 120px !important;
   }
  .double_quote{
    width: 27px !important;
  }
  .evercomm_10font{
    font-size: 1.4rem !important;
  }
  .thumbnail {
    height: 57% !important;
  }
  .wrapper {
    grid-auto-rows:  minmax(200px, 200px);
    gap: 25px;

  }
  .news_tags {
    padding-left: 0px !important;
    padding-top: 20px !important;
  }
  .wrapper h2{
    font-size: 1rem !important;
   }
   .wrapper h5{
    font-size: 0.9rem !important;
   }
   .wrapper p{
    font-size: 10px !important;
   }
   .evercomm10_banner{
    width: 400px !important;
   }
   .Evercomm_Anni_whiteLogo{
    width: 400px !important;
   }
}
@media (min-width:600px) and (max-width:700px) {

  .art2_header{
    font-size: 2.2rem !important;
    }
  .mapLogo{
    width: 120px !important;
   }
  .double_quote{
    width: 25px !important;
  }
  .evercomm_10font{
    font-size: 1.2rem !important;
  }
  .thumbnail {
    height: 56% !important;
  }
  .wrapper {
    gap: 20px;
    grid-auto-rows:  minmax(200px, 200px);
  } 
  .news_tags {
    padding-left: 0px !important;
    padding-top: 20px !important;
  }
  .wrapper h2{
    font-size: 1rem !important;
   }
   .wrapper h5{
    font-size: 0.9rem !important;
   }
   .wrapper p{
    font-size: 10px !important;
   }
   .evercomm10_banner{
    width: 400px !important;
   }
   .Evercomm_Anni_whiteLogo{
    width: 400px !important;
   }
   .workshop_img1{
    width: 273px !important;
   }
   .workshop_img2{
    width: 250px !important;
   }
}
@media (min-width:500px) and (max-width:600px) {
  .art2_header{
    font-size: 2rem !important;
    }
  .mapLogo{
    width: 120px !important;
   }
  .double_quote{
    width: 25px !important;
  }
  .thumbnail {
    height: 56% !important;
  }
  .wrapper {
    gap: 15px !important;
    grid-auto-rows:  minmax(180px, 180px);
  } 
  .news_tags {
    padding-left: 0px !important;
    padding-top: 20px !important;
  }
  .wrapper h2{
    font-size: 0.8rem !important;
   }
   .wrapper h5{
    font-size: 0.8rem !important;
   }
   .wrapper p{
    font-size: 10px !important;
   }
   .evercomm10_banner{
    width: 350px !important;
   }
   .Evercomm_Anni_whiteLogo{
    width: 350px !important;
   }
   .workshop_img1{
    width: 223px !important;
   }
   .workshop_img2{
    width: 200px !important;
   }
}
@media (min-width:450px) and (max-width:500px) {
  .mapLogo{
    width: 105px !important;
   }
  .double_quote{
    width: 25px !important;
  }
  .thumbnail {
    height: 64% !important;
  }
  .news_tags {
    padding-left: 20px !important;
    padding-top: 10px !important;
  }
  .news_tags h3{
font-size: 1.8rem !important;
  }
  .news_tags h5{
font-size: 1rem !important;
  }
  .news_tags h4{
font-size: 1.2rem !important;
  }
  .wrapper {
    gap: 25px;
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: minmax(180px, 1fr) auto minmax(180px, 200px); */
    grid-template-rows: minmax(400px, 1fr) auto;

  }
  .wrapper :nth-child(1) {
    grid-column: 1/3;
    grid-row: 1 / 2;
  }
  .wrapper :nth-child(2) {
    grid-column: 1/2;
    grid-row: 2/2;
  }
  .wrapper :nth-child(3) {
    grid-column: 2/3;
    grid-row: 2/3;
  }
  .wrapper h2{
    font-size: 1.1rem !important;
   }
}
@media (max-width:450px) {
  .sg_innovate_logo{
    height: 31px !important;
   }
   .bCorp_logo{
    height: 31px !important;
   }
  .wrapper h2{
    font-size: 1.1rem !important;
   }
  .mapLogo{
    width: 90px !important;
   }
  .double_quote{
    width: 25px !important;
  }
  .thumbnail {
    height: 56% !important; 
  }
  .news_tags {
    padding-left: 20px !important;
    padding-top: 10px !important;
  }
  .news_tags h3{
font-size: 1.8rem !important;
  }
  .news_tags h5{
font-size: 1rem !important;
  }
  .news_tags h4{
font-size: 1.2rem !important;
  }
  .wrapper {
    gap: 25px;
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: minmax(180px, 1fr) repeat(5, minmax(200px, 200px)); */
    /* grid-auto-rows: minmax(180px, 200px) */
    /* grid-template-rows: minmax(180px, 1fr) auto minmax(200px, 200px); */
    grid-template-rows: minmax(400px, 1fr) auto;
  }
  .wrapper :nth-child(1) {
    grid-column: 1/3;
    grid-row: 1 / 2;
  }
  .wrapper :nth-child(2) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .wrapper :nth-child(3) {
    grid-column: 2/3;
    grid-row: 2/3;
  }
  .nxmap2Banner {
    min-height: 300px !important;
  }
  .nxmapOne {
    top: 22% !important;
    left: 30% !important;
    width: 60% !important;
  }
  .nxmapTwo {
    top: 35% !important;
    left: -2% !important;
    width: 60% !important;
  } 
  .nxmapTwoOne {
    top: 42% !important;
    left: 8% !important;
    width: 18% !important;
  }
  .textOne {
    top: 25% !important;
    left: -8% !important;
  }
  .textTwo {
    width: 80px !important;
    font-size: 10px;
    top: 50% !important;
    left: 76% !important;
  } 
.textTwo p {
    font-size: 7px !important;
    line-height: 7px !important;
  }
  .textOne p {
    font-size: 7px !important;
    line-height: 7px !important;
  }
.enchanced_features {
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px 20px !important;
}
.enchanced_title{
  padding-left: 20px !important;
}
.enchanced_features div {
  padding: 10px 10px !important;
  height: 170px !important;
}
.nxmapLogos{
 height: 28% !important;
}
.evercommLogos{
 height: 20% !important;

}
.emissionboundary,.trainerSession,.seamless {
  max-height: unset !important;
}
.trainingPartner{
  line-height: unset !important;
}
.sampleDiv div{
max-width: 48% !important;
}
.sampleDiv p{
  width: 100% !important;
  }
.nxmapDropdown select:focus {
border-color: #7d7d7d !important;
}
}
@media (max-width:370px) {
  .news_tags h3{
    font-size: 1.3rem !important;
      }
}
.tag_ele p:nth-child(2){
  background-color: #ffc107 !important;
}
.tag_ele p:nth-child(1){
  background-color: #0dcaf0 !important;
}
@media (max-width:350px) {
  .evercomm_10font{
    font-size: 0.9rem !important;
  }
  .news_tags {
    padding-left: 0px !important;
  }
  .news_tags h3{
font-size: 1.3rem !important;
  }
  .news_tags h4{
font-size: 1.1rem !important;
  }
  .news_tags h5{
font-size: 1rem !important;
  }
  .b_corp_logo {
    width: 75px !important;
    height: 100px !important;
  }
}
.news_date {
  background-color: #fff !important;
}

.evercom_wrapper{
    display: grid;
    grid-template-columns:  repeat(2,minmax(200px, 1fr));
    gap: 50px;
    grid-auto-rows: minmax(300px, 1fr);
}
.about_wrapper{
  display: grid;
  grid-template-columns:  repeat(4,minmax(250px, 1fr));
  gap: 50px;
  grid-auto-rows: minmax(300px, 1fr);
}
@media (max-width: 1000px){
  .evercom_wrapper{
    gap: 30px !important;
    grid-auto-rows: minmax(250px, 1fr);
  }
}
@media (max-width: 600px){
  .button-location{
    margin-top:5px !important;
    height: 30px !important;
    width: 100px !important
  }
  .evercomm_10font{
    font-size: 1.3rem !important;
  }
  .evercom_wrapper{
    grid-template-columns: 1fr;
    }
}
@media (max-width: 576px){
  .about_ele img{
   max-width: 50% !important;
  }
 
}

.image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
}

.gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15%; /* Adjust this value to control the height of the gradient section */
  background: linear-gradient(to top,  white,rgba(255, 255, 255, 0.7),rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0)); /* Gradient from transparent to white */
  z-index: 1;
}
@media(max-width: 1000px){
.modal_height {
  min-height: unset !important;
}
}
@media (max-width: 500px){
  .navbar-brand{
    margin-right: 10px !important;
  }
  .NxPlan_frame_header{
    margin-top: 48px !important;
  }
  .workshop_img1{
    width: 173px !important;
   }
   .workshop_img2{
    width: 150px !important;
   }
}
@media (max-width: 650px) {
  #pkkProfile div{
   min-height: unset !important;
   position: unset !important;
  bottom: unset !important;
   }
}

@media (max-width: 768px){
  .Nxplan_KTIS{
    height: 60px !important;
  }
  .NxPlan_frame {
    flex-wrap: wrap !important;
    align-content: center !important;
  }
  .NxPlan_frame_header{
    margin: 48px 0px 0px !important;
  }
  .NxPlan_frame_header h2{
    padding: 0px !important;
    margin: 0px !important;
  }
  }

 @media (max-width: 380px){
  #btn_uae {
    min-width: 100% !important; 
    /* text-align: start; */
  }
 }

 @media (min-width: 1200px) and (max-width: 1277px) {
  .COP28Banner {
    width: 450px !important
  }
 }

 @media (max-width: 986px){
  .nxMapTabs {
    display: none !important;
    }
   .nxmapDropdown {
    display: block !important;
   }
   .course-container{
    background-color: #0772d0;
    width: 100% !important;
    padding: 30px !important;
    border-radius: 10px !important;
   }
   .course-container div:nth-child(1){
    margin-left: 0px !important;
  }
 }
 @media (min-width: 650px) and (max-width: 986px) {
  .evercomm_logo{
    width:150px !important;
   }
   /* .nav_logo, .nav_logo_unreachable{
   height: 40px !important;
   } */
   /* .course-container{
    width: 100% !important;
    padding: 30px !important;
   } */
     .singapore_training {
       width: 130px !important;
     }
  
     .singapore-logo-container {
       min-width: 180px !important;
       height: 180px !important;
       margin-right: 10px !important;
     }
     }
 @media (min-width: 986px) and (max-width: 1200px) {
  .evercomm_logo{
    width:125px !important;
   }
  .singapore_training{
width: 130px !important;
   }
   .singapore-logo-container{
    min-width: 180px !important;
    height: 180px !important;
   }
 }
 @media(min-width: 1500px) {
  .navbar-brand{
    margin-right: 40px !important;
  }
  .FA_BCorp_logo, .FA_COP28_logo, .FA_SGC_logo, .unresonable_logo_nav{
    margin-left: 40px !important;
  }
 }
 @media(min-width: 992px) and (max-width: 1152px){
  .enterprise-solution{
    height: 148px !important;
  }
 }
 @media(min-width: 992px) and (max-width: 1000px){
  .navbar-brand{
    margin-right: 30px !important;
  }
  .FA_BCorp_logo, .FA_COP28_logo, .FA_SGC_logo, .unresonable_logo_nav{
    margin-left: 30px !important;
  }
 }
 @media(min-width: 1000px) and (max-width: 1100px){
  .navbar-brand{
    margin-right: 30px !important;
  }

.nxmapTwo {
top: 30% !important;
left: 10% !important;
}
.nxmapTwoOne {
  top: 35% !important;
  left: 15% !important;
}
.textOne {
  top: 23% !important;
  left: 1% !important;
}
.textTwo {
  top: 23% !important;
  left: 8% !important;
}
  .FA_BCorp_logo, .FA_COP28_logo, .FA_SGC_logo, .unresonable_logo_nav{
    margin-left: 30px !important;
  }
 }
 @media(min-width: 769px) and (max-width: 992px){
  .navbar-brand{
    margin-right: 30px !important;
  }
  .FA_BCorp_logo, .FA_COP28_logo, .FA_SGC_logo, .unresonable_logo_nav{
    margin-left: 30px !important;
  }
 }
 @media(min-width: 481px) and (max-width: 768px){
  .FA_BCorp_logo, .FA_COP28_logo, .FA_SGC_logo, .unresonable_logo_nav{
    margin-left: 18px !important;
  }
  .navbar-toggler {
    padding: 4px 4px !important;
  }
  .enchanced_features {
    /* grid-template-columns: 1fr 1fr; */
    gap: 20px;
    padding: 20px 20px !important;
  }
  
 }
 @media(min-width: 320px) and (max-width: 480px){
  .navbar-brand{
    margin-right: 10px !important;
  }
  .FA_BCorp_logo, .FA_COP28_logo, .FA_SGC_logo, .unresonable_logo_nav{
    margin-left: 10px !important;
  }
  .navbar-toggler-icon {
   height: 20px !important;
  }
  .navbar-toggler {
    padding: 4px 4px !important;
  }
 }
 @media(max-width: 320px){
  .evercomm_logo{
    width: 70px !important;
    margin-bottom: 5px !important;
   }
   .nav_logo {
    height: 23px !important;
   }
   .nav_logo_unreachable{
    height: 23px !important;
    width: 46px !important;

   }
  .navbar-brand{
    margin-right: 10px !important;
    max-height: 30px !important;
    padding: 0px !important;
  }
  .FA_BCorp_logo, .FA_COP28_logo, .FA_SGC_logo, .unresonable_logo_nav{
    margin-left: 10px !important;
  }
  .navbar-toggler-icon {
   height: 20px !important;
  }
  .navbar-toggler {
    padding: 4px 4px !important;
  }
 }
@media  (max-width: 768px) {
  .footer_logo {
    justify-content: unset !important;
  }
  /* .unreasonable_logo {
    width: 150px !important;
    height: 75px !important
  } */
}
@media(min-width:1153px) and (max-width:1195px) {
  .navbar-brand{
    margin-right: 20px !important;
  }
  .FA_BCorp_logo, .FA_COP28_logo, .FA_SGC_logo, .unresonable_logo_nav{
    margin-left: 20px !important;
  }
 }

 @media  (max-width: 991px) {
  .footer-text-one {
   display: block !important;
  }
  .footer-text-two{
   display: none !important;
  }
 }
 @media  (max-width: 650px) {
  .footer-list {
   width: 150px !important;
  }
  .singapore_training {
    width: 110px !important;
  }
  .course-container div {
  flex-wrap: wrap !important;
  justify-content: center !important;

  }
  .singapore-logo-container {
    min-width: 160px !important;
    height: 160px !important;
    margin-right: 10px !important;
    margin-bottom: 20px !important;
  }
 
 }


 .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #010244db;
 }
 .nav-pills .nav-link.active{
  background-color: #0772d0 !important;
  color: #fff !important;
 }
 .nav-pills .nav-link{
  color: #0772d0 !important;
 }
 .nav-tabs .nav-link{
  background-color: #fff;
 }
 .award_grid {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  grid-auto-rows:  1fr;
  gap: 50px;
  padding: 50px 50px !important;
 }

.nav-pills .nav-link{ 
  border: 1px solid #0772d0;
}

@media (min-width: 1300px) and (max-width: 1400px) {

  .textOne {
    top: 23% !important;
    left: 11% !important;
  }
  .textTwo {
    top: 55% !important;
    left: 69% !important;
  } 
}

@media (min-width: 1400px) and (max-width: 1500px) {

    .textOne {
      top: 23% !important;
      left: 13% !important;
    }
    .textTwo {
      top: 55% !important;
      left: 69% !important;
    } 
}

@media (min-width: 1900px) and (max-width: 2000px) {

  .textOne {
    top: 23% !important;
    left: 18% !important;
  }
  .textTwo {
    top: 55% !important;
    left: 68% !important;
  } 
}

@media (min-width: 2000px) and (max-width: 2100px) {

  .textOne {
    top: 23% !important;
    left: 18% !important;
  }
  .textTwo {
    top: 55% !important;
    left: 68% !important;
  } 
}
@media (min-width: 2100px) and (max-width: 2200px) {

  .textOne {
    top: 23% !important;
    left: 18% !important;
  }
  .textTwo {
    top: 55% !important;
    left: 68% !important;
  } 
}
@media (max-width: 431px) {
  .textOne {
    top: 25% !important;
    left: -10% !important;
  }
  .textTwo {
    width: 80px !important;
    font-size: 10px;
    top: 51% !important;
    left: 74% !important;
  }
  
}
@media (max-width: 415px) {
  .textOne {
    top: 25% !important;
    left: -10% !important;
  }
  .textTwo {
    width: 80px !important;
    font-size: 10px;
    top: 50% !important;
    left: 75% !important;
  }
  
}
@media (max-width: 400px) {
  .textOne {
    top: 25% !important;
    left: -13% !important;
  }
  .textTwo {
    width: 80px !important;
    font-size: 10px;
    top: 48% !important;
    left: 76% !important;
  }

}
.ctbc_signing_img {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: auto auto;
}

.ctbc_signing_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ctbc_signing_img img:nth-child(1) {
  grid-column: span 4;
}

.ctbc_signing_img img:nth-child(2) {
  grid-column: span 4;
}

.ctbc_signing_img img:nth-child(3) {
  grid-column: span 3;
}

.ctbc_signing_img img:nth-child(4) {
  grid-column: span 5;
}

@media (min-width: 450px) and (max-width: 768px) {
.nxmapOne {
  top: 25% !important;
  left: 30% !important;
  width: 60% !important;
}
.nxmapTwo {
  top: 35% !important;
  left: -2% !important;
  width: 60% !important;
} 
.nxmapTwoOne {
  top: 42% !important;
  left: 8% !important;
  width: 18% !important;
}
.textOne {
  top: 26% !important;
  left: -13% !important;
}
.textTwo {
  width: 188px !important;
  top: 45% !important;
  left: 77% !important;
}
}
@media (min-width: 769px) and (max-width: 820px) {
  .bannerText button {
    width: 250px !important;
  }
  .nxmapOne {
    top: 31% !important;
    left: 32% !important;
    width: 50% !important;
  }
  .nxmapTwo {
    top: 39% !important;
    left: 9% !important;
    width: 50% !important;
  } 
  .nxmapTwoOne {
    top: 43% !important;
    left: 14% !important;
    width: 18% !important;
  }
  .textOne {
    top: 32% !important;
    left: -9% !important;
  }
  .textTwo {
    width: 188px !important;
    top: 46% !important;
    left: 77% !important;
  }
  }
  @media (min-width: 821px) and (max-width: 1024px) {
    
    .bannerText button {
      width: 320px !important;
    }
    .nxmapOne {
      top: 38% !important;
      left: 32% !important;
      width: 60% !important;
    }
    .nxmapTwo {
      top: 48% !important;
      left: 6% !important;
      width: 60% !important;
    } 
    .nxmapTwoOne {
      top: 54% !important;
      left: 14% !important;
      width: 18% !important;
    }
    .textOne {
      top: 40% !important;
      left: 0% !important;
    }
    .textTwo {
      width: 220px !important;
      top: 57% !important;
      left: 79% !important;
    }
    }
    .cookie-consent {
      /* display: flex;
      justify-content: center; */
      width: 90%;
      background-color: #ffffff;
      color: #040202;
      position: fixed;
      bottom: 10%;
      left: 50%;
      padding: 30px;
      text-align: start;
      z-index: 1000;
      transform: translateX(-50%);
      border-radius: 20px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      /* display: none;\ */
    }
    .cookie-consent button {
      padding: 5px 20px;
      /* height: 30px !important; */
      border: 1px solid #007bff;
      border-radius: 20px !important;
      background-color: #ffff;
    }
    .cookie-consent button:hover {
      background-color: #007bff !important;
      border: 1px solid #007bff !important;
      color: #fff ;
    }
    .course-container div:nth-child(1){
      background-color: #0772d0;
      border-radius: 10px !important;
    }

@media (max-width: 1000px) {
          .ctbc_script_container{
            justify-content: center !important;
            margin-left: 0px !important;
          }
          .ctbc_banner {
            background-position: 80% 50% !important;
          }
          .ctbc_banner_script {
            width: 90% !important;
            padding: 30px 30px !important;
          }
    
        }

.nxMapTabs::-webkit-scrollbar {
  width: 8px !important;
  border-radius: 4px !important;
}
.nxMapTabs::-webkit-scrollbar-thumb {
  border-radius: 10px; /* Optional: rounded edges */
}
.float{
	position:fixed;
	width:59px;
	height:59px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 1px 1px 2px #393838;
  z-index:100;
}

.my-float{
  position: relative;
  bottom: -8px;
}


 